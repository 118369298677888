import React, { useEffect, useState, Fragment } from 'react'
import moment from 'moment'
import BigNumber from 'bignumber.js'
import ThemedTooltip from '../../ThemedTooltip'
import WithdrawDialog from '../withdraw-dialog'
import { get } from 'lodash'
import { TOKENS } from 'util/web3'
import { useRecoilValue } from 'recoil'
import { Loader, Whisper } from 'rsuite'
import { stakingState } from 'util/state'
import { numberWithCommas } from 'util/utils'
import { responsiveWidthL } from 'util/const'
import { useMediaQuery } from 'react-responsive'
import {
  StakingV1SummaryBase,
  StakingV2SummaryBase,
  resolveStakingSummary,
  calculateStakingEarned,
  STMX_FIXED_SIGNIFICANCE,
  calculateStakingV2Earned,
  StakingCalculationValues
} from '@stormx/calculate-staking'
import {
  Text,
  Line,
  IconInfo,
  SectionRow,
  EarningsCol,
  SectionText,
  CustomButton,
  EarningsWrapper,
  CustomButtonWrapper
} from './components/index'

export interface StakingBalance {
  ATH: {
    loaded: boolean
    locked: string
    unlocked: string
  }
  STMX: {
    loaded: boolean
    locked: string
    unlocked: string
  }
  STMX_V2: {
    loaded: boolean
    locked: string
    unlocked: string
  }
}

interface EarningsProps {
  token: string
  loading: boolean
  hasError: boolean
  cooldownAmount: string
  balance: StakingBalance
  isMobileBrowser: boolean
  setTokenSelected: React.Dispatch<React.SetStateAction<string>>
  setShowUnstakeDialog: React.Dispatch<React.SetStateAction<boolean>>
  setShowCooldownDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const Earnings = ({
  token,
  loading,
  balance,
  hasError,
  cooldownAmount,
  isMobileBrowser,
  setTokenSelected,
  setShowUnstakeDialog,
  setShowCooldownDialog
}: EarningsProps) => {
  const tokenFormatted = token.replace(' ', '_')
  const tokenBalance = get(balance, `${tokenFormatted}`)

  const stakingstate = useRecoilValue(stakingState)
  /* eslint-disable-next-line */
  const stakingAccount = {
    ...stakingstate,
    summary: get(stakingstate, `summary.${tokenFormatted}`, undefined)
  }
  const [stakingProfit, setStakingProfit] = useState('')
  const [showWithdraw, setShowWithdraw] = useState(false)

  const isSmallScreen = useMediaQuery({
    query: `(max-width: ${responsiveWidthL}px)`
  })

  const didNotLoad = tokenBalance.loaded && tokenBalance.locked === -1 && tokenBalance.unlocked === -1
  const notFullyLoaded = loading || !tokenBalance.loaded || didNotLoad || hasError || !stakingAccount.summary
  const minutesToAdd = 15
  const formattedRewardDate =
    stakingAccount.summary && moment(stakingAccount.summary.weekEndsAt).add(minutesToAdd, 'minutes').format('LLL')

  const totalStaked = notFullyLoaded || numberWithCommas(parseFloat(new BigNumber(tokenBalance.locked).toString()))
  const totalBalance =
    notFullyLoaded ||
    numberWithCommas(parseFloat(new BigNumber(tokenBalance.locked).plus(tokenBalance.unlocked).toString()))

  useEffect(() => {
    const calculateStakingProfit = () => {
      if (stakingAccount.summary) {
        if (stakingAccount.summary.disconnectedRewardsCurrrentWeek) {
          setStakingProfit(StakingCalculationValues.UNLINKED_REWARDS_WALLET_THIS_CYCLE)
        } else {
          const stakingSummary = resolveStakingSummary(stakingAccount.summary)
          const earnings =
            token === TOKENS.STMX_V2
              ? calculateStakingV2Earned(stakingSummary as StakingV2SummaryBase)
              : calculateStakingEarned(stakingSummary as StakingV1SummaryBase)

          if (earnings.isLessThan(0)) {
            setStakingProfit(StakingCalculationValues.UNSTAKED_THIS_CYCLE)
          } else {
            setStakingProfit(earnings.toFixed(STMX_FIXED_SIGNIFICANCE))
          }
        }
      } else {
        setStakingProfit('')
      }
    }

    const interval = setInterval(() => calculateStakingProfit(), 50)
    return () => clearInterval(interval)
  }, [stakingAccount, token])

  const RewardDate = ({
    formattedRewardDate,
    notFullyLoaded,
    stakingProfit
  }: {
    formattedRewardDate: string | undefined
    notFullyLoaded: boolean
    stakingProfit: string
  }) => {
    const stakingstate = useRecoilValue(stakingState)
    const stakingAccount = {
      ...stakingstate,
      summary: get(stakingstate, `summary.${tokenFormatted}`, undefined)
    }

    if (!notFullyLoaded && stakingAccount.summary) {
      if (stakingProfit === StakingCalculationValues.UNSTAKED_THIS_CYCLE) {
        return <Fragment>None</Fragment>
      } else {
        return <Fragment>{formattedRewardDate}</Fragment>
      }
    } else {
      return <Loader />
    }
  }

  const TotalEarned = ({ notFullyLoaded }: { notFullyLoaded: boolean }) => {
    const stakingstate = useRecoilValue(stakingState)
    const stakingAccount = {
      ...stakingstate,
      summary: get(stakingstate, `summary.${tokenFormatted}`, undefined)
    }

    if (!notFullyLoaded && stakingAccount.summary) {
      if (!stakingAccount.summary.totalEarned) {
        return <Fragment>0</Fragment>
      } else {
        return (
          <Fragment>
            {numberWithCommas(
              new BigNumber(parseFloat(stakingAccount.summary.totalEarned)).toFixed(STMX_FIXED_SIGNIFICANCE)
            )}{' '}
            {token}
          </Fragment>
        )
      }
    } else {
      return <Loader />
    }
  }

  const WeeklyBonus = ({
    formattedRewardDate,
    notFullyLoaded,
    stakingProfit
  }: {
    formattedRewardDate: string | undefined
    notFullyLoaded: boolean
    stakingProfit: string
  }) => {
    const stakingstate = useRecoilValue(stakingState)
    const stakingAccount = {
      ...stakingstate,
      summary: get(stakingstate, `summary.${tokenFormatted}`, undefined)
    }

    if (stakingAccount.notLoaded || !stakingProfit || notFullyLoaded) {
      return <Loader />
    } else {
      if (parseInt(stakingProfit) < 0) {
        let text = ''
        let tooltip = ''
        if (stakingProfit === StakingCalculationValues.UNLINKED_REWARDS_WALLET_THIS_CYCLE) {
          text = 'You Unlinked Rewards Wallet'
          tooltip = `Unlinking Rewards Wallet forfeits your bonus for the week. You can start earning your bonus again on ${formattedRewardDate}`
        } else if (stakingProfit === StakingCalculationValues.UNSTAKED_THIS_CYCLE) {
          text = 'You Unstaked this week'
          tooltip = `Unstaking forfeits your bonus for the week. You can start earning your bonus again on ${formattedRewardDate}`
        }

        return (
          <Fragment>
            {text}
            <Whisper
              preventOverflow
              trigger='hover'
              speaker={<ThemedTooltip>{tooltip}</ThemedTooltip>}
              placement='bottom'
            >
              <IconInfo />
            </Whisper>
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            {numberWithCommas(new BigNumber(parseFloat(stakingProfit)).toFixed(STMX_FIXED_SIGNIFICANCE))}{' '}
            {token === TOKENS.STMX_V2 ? TOKENS.STMX : token}
          </Fragment>
        )
      }
    }
  }

  return (
    <EarningsWrapper>
      <SectionRow>
        <EarningsCol lg={6} addborder={!isSmallScreen ? 'true' : ''}>
          <Text section>Total Earned</Text>
          <SectionText>
            <TotalEarned notFullyLoaded={notFullyLoaded} />
          </SectionText>
          <CustomButtonWrapper>
            <CustomButton
              disabled={BigNumber(get(stakingAccount, 'summary.totalEarned', 0)).isEqualTo(0)}
              onClick={() => setShowWithdraw(true)}
            >
              Withdraw
            </CustomButton>
          </CustomButtonWrapper>
        </EarningsCol>

        {isSmallScreen && <Line />}

        <EarningsCol lg={6} addborder={!isSmallScreen ? 'true' : ''}>
          <Text section>Weekly Earnings</Text>
          <SectionText>
            <WeeklyBonus
              formattedRewardDate={formattedRewardDate}
              notFullyLoaded={notFullyLoaded}
              stakingProfit={stakingProfit}
            />
          </SectionText>
        </EarningsCol>

        {isSmallScreen && <Line />}

        <EarningsCol lg={6} addborder={!isSmallScreen ? 'true' : ''}>
          <Text section>Reward Date</Text>
          <SectionText>
            <RewardDate
              formattedRewardDate={formattedRewardDate}
              notFullyLoaded={notFullyLoaded}
              stakingProfit={stakingProfit}
            />
          </SectionText>
        </EarningsCol>

        {isSmallScreen && <Line />}

        <EarningsCol lg={6}>
          <Text section>Total Staked</Text>
          <SectionText>{notFullyLoaded ? <Loader /> : `${totalStaked} out of ${totalBalance}`}</SectionText>

          <CustomButtonWrapper>
            <CustomButton
              disabled={BigNumber(tokenBalance.locked).isLessThanOrEqualTo(0)}
              onClick={() => {
                if (parseInt(cooldownAmount) > 0 && token === TOKENS.STMX_V2) {
                  setShowCooldownDialog(true)
                } else {
                  setTokenSelected(token)
                  setShowUnstakeDialog(true)
                }
              }}
            >
              Unstake
            </CustomButton>
          </CustomButtonWrapper>
        </EarningsCol>
      </SectionRow>

      <WithdrawDialog showWithdraw={showWithdraw} isMobileBrowser={isMobileBrowser} setShowWithdraw={setShowWithdraw} />
    </EarningsWrapper>
  )
}

export default Earnings
